import React from "react";
import { Google } from "assets/icons/internal";
import { Button } from "@ds-proxy";
import BusinessIcon from "@mui/icons-material/Business";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";

const icons = {
  google: {
    Icon: Google,
  },
  saml: {
    Icon: BusinessIcon,
  },
  password: {
    Icon: PermIdentityIcon,
  },
};

export interface ProviderButtonProps {
  type: keyof typeof icons;
  label: string;
  onClick: () => void;
}

export function ProviderButton(props: ProviderButtonProps) {
  const { Icon } = icons[props.type];
  return (
    <Button
      size="large"
      variant="outlined"
      onClick={props.onClick}
      style={{
        textTransform: "none",
        color: "var(--text-primary)",
      }}
      color={"secondary"}
    >
      <div
        style={{
          display: "flex",
          gap: "var(--spacing-m)",
          width: "100%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          alignItems: "center",
        }}
      >
        <Icon style={{ fontSize: 20, width: 20, height: 20, minWidth: 20 }} />
        <span>{props.label}</span>
      </div>
    </Button>
  );
}
