import CopyToClipboard from "react-copy-to-clipboard";
import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { ENV } from "runenv";
import { useFieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { Can } from "shared/permissions";
import { Icon } from "ds-ui";
import {
  Button,
  Divider,
  Typography,
  Dialog,
  FormHelperText,
  IconButton,
  DialogTitle,
  DialogContent,
} from "@ds-proxy";
import { Delete, Edit, ContentCopy } from "@mui/icons-material";
import { types } from "api";
import { Stack, HStack, Spacer } from "libs/layouts";
import { required, TextFieldComponent } from "modules/form-utils";
import { notification } from "modules/notification";
import { LoadingButton, Box, useFormDialog, Text } from "ui";
import { useSAMLProviderCreate, useSAMLProviderDelete, useSAMLProviderUpdate } from "../apiHooks";

const messages = {
  formStep1: "STEP 1: Configure SAML provider",
  formStep2: "STEP 2: Fill additional information",
  formStep2Hint: "All fields are required",
  form: {
    authCallbackLabel: "Add this authorization callback URL to your SAML app configuration",
    name: "Name",
    entityId: "Identity provider entity ID",
    ssoUrl: "SSO URL",
    spId: "Service provider entity ID",
    certificate: "Certificate",
    addCertificate: "+Add additional certificate",
  },
  buttons: {
    save: "Save",
    cancel: "Cancel",
    Delete: "Delete",
  },
  notification: {
    copyCallBack: "Callback URL was copied",
  },
  addNew: "Add new saml",
  createNewProvider: "Add new saml provider",
  editProvider: "Edit saml provider",
  cancel: "Cancel",
  created: "Provider was created",
  updated: "Provider was updated",
  confirmDelete: (provider: string) => `Are you sure you want to delete "${provider}" provider?`,
};

const localTheme = {
  callbackURLBackground: "var(--background-idle)",
  copyURLBackground: "var(--background-active)",
};

function CallbackURL({ value }: any) {
  return (
    <Stack space={2}>
      <Typography>{messages.form.authCallbackLabel}</Typography>
      <HStack space={0}>
        <Box
          style={{ flexGrow: 1 }}
          sx={{
            paddingX: "var(--spacing-s)",
            paddingY: "var(--spacing-l)",
            background: localTheme.callbackURLBackground,
          }}
        >
          <Typography noWrap>{value}</Typography>
        </Box>
        <HStack.Spacer />
        <Box sx={{ background: localTheme.copyURLBackground, centerContent: true, width: "52px" }}>
          <CopyToClipboard
            text={value}
            onCopy={() => notification.success({ message: messages.notification.copyCallBack })}
          >
            <ContentCopy fontSize="small" cursor="pointer" />
          </CopyToClipboard>
        </Box>
      </HStack>
    </Stack>
  );
}

function Certificate({ name }: any) {
  const { fields } = useFieldArray(name);
  return (
    <Stack align="flex-start" fullWidth>
      {fields.map((name, i) => {
        return (
          <Stack space={1} fullWidth key={name}>
            <HStack space={2} fullWidth>
              <Typography variant="caption">{messages.form.certificate}</Typography>
              <HStack.Spacer />
              <Can do={"admin_saml__modify"}>
                <IconButton color="secondary" size="small" onClick={() => fields.remove(i)}>
                  <Delete fontSize="small" />
                </IconButton>
              </Can>
            </HStack>
            <Field
              validate={required}
              name={name}
              render={({ input }) => {
                return (
                  <Box
                    as={TextFieldComponent}
                    {...input}
                    placeholder="Certificate"
                    multiline
                    sx={{
                      paddingX: "var(--spacing-s)",
                      paddingTop: "var(--spacing-m)",
                      paddingBottom: "var(--spacing-3xl)",
                      background: localTheme.callbackURLBackground,
                    }}
                    style={{ appearance: "none" }}
                  />
                );
              }}
            />
          </Stack>
        );
      })}
      <Can do={"admin_saml__modify"}>
        <Button onClick={() => fields.push("")}>{messages.form.addCertificate}</Button>
      </Can>
    </Stack>
  );
}

function SAMLProviderForm({
  submitError,
  onCancel,
  loading,
}: {
  loading?: boolean;
  onCancel?: () => any;
  submitError?: string;
}) {
  return (
    <Box sx={{ paddingY: "var(--spacing-m)" }}>
      <Stack space={7}>
        <Stack space={1}>
          <Typography style={{ fontWeight: 500 }}>{messages.formStep1}</Typography>
          <CallbackURL value={ENV.SSO_AUTH_URL} />
        </Stack>
        <Stack space={5}>
          <Typography style={{ fontWeight: 500 }}>
            {messages.formStep2}
            <Typography component="span" display="inline">
              ({messages.formStep2Hint})
            </Typography>
          </Typography>
          <Field
            validate={required}
            name="name"
            label={messages.form.name}
            component={TextFieldComponent}
          ></Field>
          <Field
            validate={required}
            name="idp_entity_id"
            label={messages.form.entityId}
            component={TextFieldComponent}
          ></Field>
          <Field
            name="sso_url"
            validate={required}
            label={messages.form.ssoUrl}
            component={TextFieldComponent}
          ></Field>
          <Field
            validate={required}
            name="rp_entity_id"
            label={messages.form.spId}
            component={TextFieldComponent}
          ></Field>
          <Certificate name="x509_certificates" />
        </Stack>
        <Divider />
        {submitError && <FormHelperText error>{submitError}</FormHelperText>}
        <HStack space={1}>
          <Spacer />
          <Button onClick={onCancel}>{messages.buttons.cancel}</Button>
          <Can do={"admin_saml__modify"}>
            <LoadingButton isLoading={loading} type="submit" color="primary" variant="contained">
              {messages.buttons.save}
            </LoadingButton>
          </Can>
        </HStack>
      </Stack>
    </Box>
  );
}

function getSubmitError(error: unknown): string {
  return (error as any)?.response.data.error || (error as any)?.message;
}

interface SAMLProviderModalProps {
  open: boolean;
  title: string;
  initialValues: Partial<types.SAMLProvider>;
  onCancel: () => void;
  onSubmit: (provider: types.SAMLProvider) => void;
  error: unknown;
}

function SAMLProviderModal({
  open,
  title,
  error,
  initialValues,
  onCancel,
  onSubmit,
}: SAMLProviderModalProps) {
  if (!open) {
    return null;
  }

  return (
    <Dialog open>
      <DialogTitle>
        <Typography variant="h5" style={{ textTransform: "uppercase" }}>
          {title}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onCancel}
          sx={{
            position: "absolute",
            right: 16,
            top: 16,
          }}
        >
          <Icon name={"CloseOutlined"} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Form<types.SAMLProvider>
          keepDirtyOnReinitialize
          onSubmit={onSubmit}
          initialValues={initialValues}
          mutators={{
            ...arrayMutators,
          }}
          render={(props) => {
            return (
              <Box as="form" onSubmit={props.handleSubmit}>
                <SAMLProviderForm
                  onCancel={onCancel}
                  loading={props.submitting}
                  submitError={getSubmitError(error)}
                />
              </Box>
            );
          }}
        />
      </DialogContent>
    </Dialog>
  );
}

export function EditSAMLProvider({ provider }: { provider: types.SAMLProvider }) {
  const [isEditing, setIsEditing] = useState(false);
  const { mutateAsync: update, error } = useSAMLProviderUpdate({
    onDone() {
      setIsEditing(false);
      notification.success({
        message: messages.updated,
      });
    },
  });

  function cancel() {
    setIsEditing(false);
  }

  return (
    <>
      <SAMLProviderModal
        open={isEditing}
        title={messages.editProvider}
        initialValues={provider}
        error={error}
        onCancel={cancel}
        onSubmit={update}
      />
      <IconButton
        color="secondary"
        onClick={() => {
          setIsEditing(true);
        }}
        size="large"
      >
        <Edit fontSize="small" />
      </IconButton>
    </>
  );
}

export function AddNewSAMLProvider() {
  const [isAdding, setIsAdding] = useState(false);
  const { mutateAsync: create, error } = useSAMLProviderCreate({
    onDone() {
      setIsAdding(false);
      notification.success({
        message: messages.created,
      });
    },
  });

  function cancel() {
    setIsAdding(false);
  }

  return (
    <HStack style={{ width: "100%" }}>
      <SAMLProviderModal
        open={isAdding}
        title={messages.createNewProvider}
        initialValues={{ x509_certificates: [""], enabled: true }}
        error={error}
        onCancel={cancel}
        onSubmit={create}
      />
      <HStack.Spacer />
      <Button variant="contained" color="primary" onClick={() => setIsAdding(true)}>
        {messages.addNew}
      </Button>
    </HStack>
  );
}

export function DeleteSAMLProvider({ provider }: { provider: types.SAMLProvider }) {
  const { mutateAsync: samlProviderDelete } = useSAMLProviderDelete();
  const [deleteConfirmNode, deleteConfirmActions] = useFormDialog({
    onSubmit: () => {
      samlProviderDelete({ id: provider.id });
    },
    messages: {
      confirm: "Yes",
      cancel: "Cancel",
    },
    children: (
      <Text variant="body2" color="textSecondary">
        {messages.confirmDelete(provider.name)}
      </Text>
    ),
  });

  return (
    <>
      <IconButton
        color="secondary"
        onClick={() => {
          deleteConfirmActions.handleOpen();
        }}
        size="large"
      >
        <Delete fontSize="small" />
      </IconButton>
      {deleteConfirmNode}
    </>
  );
}
